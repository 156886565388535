import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Welcome to $PAL</h2>
        </div>
        
      </div>
    </div>
  );
};
