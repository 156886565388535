import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Tokenomics</h2>
          <p>
            $PAL has a 1% tax on every transaction dedicated towards encouraging the $PAL lifestyle, rewarding the communitiy with giveaways, marketing of the $PAL token, and more!
          </p>
        </div>
        
      </div>
    </div>
  );
};
